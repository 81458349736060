// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("@rails/activestorage").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'jquery'
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

// import 'bootstrap'
// import 'bootstrap/dist/js/bootstrap.js'
// import 'bootstrap/js/dist/util.js'
// import 'bootstrap/js/dist/alert.js'
import 'bootstrap/js/dist/collapse.js'
import 'bootstrap/js/dist/dropdown.js'
import 'bootstrap/js/dist/modal.js'

// Import CSS manifest file
import '../stylesheets/application'

import '../modules/form-validations'
import '../modules/wheel-animation'
import '../modules/view-helpers'
import '../modules/scrolling'
import '../modules/admin-rotate-wheel'

require.context('../images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)
