window.scroll_to = function(element_selector) {
  var element = document.querySelector(element_selector)

  if (!element) {
    console.error("Scroll target not found:", element_selector)
    return null
  }

  var header_height  = $("nav.navbar").outerHeight()
  var padding_height = 10
  var offset         = $(element).offset().top - header_height - padding_height

  $('html, body').animate({ scrollTop: offset }, 500)
}
