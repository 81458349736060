window.toggle_spin_notifications = function() {
  document.getElementById("notify-before-spin").style.display = "none"
  document.getElementById("notify-after-spin" ).style.display = "block"
}

window.show_modal_open_button = function() {
  var button = document.querySelector("[data-action='open-modal']")
  button.disabled = false
  button.style.display = "inline-block"
}
