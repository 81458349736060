// function all_required_inputs_valid(form) {
//   const node_list = form.querySelectorAll('input[required]')
//   if (node_list.length === 0) return true
//   const empty_inputs = Array.from(node_list).filter(function(input) { return input.value.length === 0 })
//   return empty_inputs.length === 0
// }

function disable_by_default(form) {
  form_submit_button(form).disabled = true
}

function form_valid(form) {
  return form.checkValidity()
}

function form_submit_button(form) {
  return form.querySelector("[type='submit']")
}

function process(input) {
  var form = $(input).closest("form")[0]
  var button = form_submit_button(form)
  button.disabled = !form_valid(form)
}

function validatable_forms() {
  const node_list = document.querySelectorAll('form[data-behavior="validatable"]')
  return Array.from(node_list)
}

document.addEventListener("DOMContentLoaded", function() {
  validatable_forms().forEach(function(form) { disable_by_default(form) })
  $('form[data-behavior="validatable"] input').change(function(event) { process(this) })
  $('form[data-behavior="validatable"] input').keypress(function(event) { process(this) })
})
