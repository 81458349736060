document.addEventListener("DOMContentLoaded", function() {
  if( $('#rotate-counter').length ) {
    $('body').css('cursor','move');

    var element_id = '#wheel';
    var rotate_counter = '#rotate-counter';
    
    var degree;
    var mapService = this.mapService;
    $(document).ready(function () {
      function rotateOnMouse(e, pw) {
        var offset = pw.offset();
        var center_x = (offset.left) + ($(pw).width() / 2);
        var center_y = (offset.top) + ($(pw).height() / 2);
        var mouse_x = e.pageX;
        var mouse_y = e.pageY;
        var radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
        degree = (radians * (180 / Math.PI) * -1) + 180;
        $(pw).css('-moz-transform', 'rotate(' + degree + 'deg)');
        $(pw).css('-webkit-transform', 'rotate(' + degree + 'deg)');
        $(pw).css('-o-transform', 'rotate(' + degree + 'deg)');
        $(pw).css('-ms-transform', 'rotate(' + degree + 'deg)');
        $(rotate_counter).text(Math.ceil(degree));
      }
      var element = $(element_id);
      element.mousedown(function (e) {
        e.preventDefault();
        $(document).bind('mousemove.rotateImg', function (e2) {
          rotateOnMouse(e2, element);
        });
      });
    });
  };
});