import "web-animations-js"

function angle_from_window() {
  if (typeof window.end_degree === "undefined") throw new Error("End degree not found in window!")
  return window.end_degree
}

function wheel_node() {
  return document.getElementById("wheel")
}

function rotate_CSS_str(angle) {
  return 'rotate(' + angle + 'deg)'
}

function generate_keyframes(final_angle) {
  return [
    { transform: rotate_CSS_str(0),                offset: 0 },
    { transform: rotate_CSS_str(-30),              offset: .1 },
    { transform: rotate_CSS_str(final_angle - 90), offset: .9 },
    { transform: rotate_CSS_str(final_angle),      offset:  1 }
  ]
}

window.animate_wheel = function(callback) {
  var angle = angle_from_window()
  console.log("passed_angle:", angle)
  var keyframes = generate_keyframes(angle)
  var options   = { duration: 9000, easing: 'ease-out', iterations: 1 }
  var player    = wheel_node().animate(keyframes, options)

  player.onfinish = function() {
    wheel_node().style.transform = rotate_CSS_str(angle)
    callback()
  }

  return player
}
